import {MDCDialog} from '@material/dialog';
import { ColorSplitterBlock } from 'babylonjs';
import { LiveDataConnection } from './liveDataConnection';

export class MediaPlayer{

    constructor(appManager){
        this.appManager = appManager

        this.initialise()
    }

    initialise(){
        this.PDFPopup = new MDCDialog(document.getElementById('PDFPopup'))
        this.TextPopup = new MDCDialog(document.getElementById('TextPopup'))
        this.MediaPopup = new MDCDialog(document.getElementById('MediaPopup'))
        this.VideoPopup = new MDCDialog(document.getElementById('VideoPopup'))
        this.PDFPopup.scrimClickAction=''
        this.TextPopup.scrimClickAction=''
        this.MediaPopup.scrimClickAction=''
        this.VideoPopup.scrimClickAction=''
        this.TextPopup.listen('MDCDialog:opened',()=> this.TextPopup.scrimClickAction='close')
        this.TextPopup.listen('MDCDialog:closed',()=> this.TextPopup.scrimClickAction='')


        this.iframePopup = document.getElementById('iframePopup')
        this.textPopupContainer = document.getElementById('textPopupContainer')
        this.textPopupTitleContainer = document.getElementById('textPopupTitleContainer')
        this.pdfDownloadLink = document.getElementById('pdfDownloadLink')
        this.videoElement = document.getElementById('videoElement')
        this.videoSourceElement = document.getElementById('videoSourceElement')

    }

    loadMedia(content){
        if(content.contentTypeId == 3){
            content.hotspot.liveDataConnection.openLiveDataModal()//liveData = new LiveDataConnection(this.appManager, content.url, content.key, content.pollingRate)
            return
        }
        let liveData
        let url = content.url
        if(url == undefined){
            if(content.message)
                this.loadText(content, content.message)
            else if(content.message == null && content.fileTypeId == null){
                if(content.name)
                    this.loadText(content, "No message provided.")
                else
                    this.loadText({name: "No name provided."}, "No message provided.")
            }
            else
                this.throwError("No file found. URL: "+url)
            return;
        }

        
        else if(content.fileTypeId == null)
            this.loadText(content,"No file type was provided. Please see the XR-Adopt content management tool.")
        else if(content.fileTypeId == 1)//model
            this.loadModel(content)
        else if(content.fileTypeId == 2)//document
            this.loadDocument(url)
        else if(content.fileTypeId == 3)//image
            this.loadImage(url)
        else if(content.fileTypeId == 4)//video
            this.loadVideo(url)
        else if(content.fileTypeId == 5)//audio
            this.loadDownload(url)
        else if(content.fileTypeId == 6)//other
            this.loadDownload(url)
        else if(content.fileTypeId == 7){//url
            if(url.includes("http"))
                this.loadDownload(url)
            else
                this.loadText(content, "Invalid URL provided.")
        }
        else if(content.fileTypeId == 8)//live data
            this.loadText(content,"Live data is not yet supported in this application. "+"fileTypeId:"+content.fileTypeId) 
        else if(content.fileTypeId == 9)//other
            this.loadDownload(url)
        else
            this.loadText(content,"Unknown file type was provided. Please see the XR-Adopt content management tool. "+"fileTypeId:"+content.fileTypeId) 
    }

    loadModel(content){
        if(!url.includes(".glb")){
            this.throwError("no .glb file found at "+url)
            return;
        }
        this.appManager.modelManager.loadNewModel(content); 
        //this.appManager.modelManager.disposeModel() 
    }
    loadText(content, contentMessage){
        this.textPopupTitleContainer.innerHTML = content.name
        this.textPopupContainer.innerHTML = contentMessage
        this.TextPopup.scrimClickAction=''
        this.TextPopup.open()
        //this.TextPopup.scrimClickAction='close'

    }
    loadDocument(url){
        if(!url.includes(".pdf")){
            this.throwError("no .pdf file found at "+url)
            return;
        }

        window.open(url, '_blank');

        /* this.pdfDownloadLink.href = url
        this.PDFPopup.open() */
    }
    loadVideo(url){
        if(!url.includes(".mp4")){
            this.throwError("no .mp4 file found at "+url)
            return;
        }
        window.open(url, '_blank');

        /* this.videoSourceElement.src = url
        this.VideoPopup.open()
        this.videoElement.load() 
        this.videoElement.pause()
        this.VideoPopup.listen('MDCDialog:closed',()=> this.videoElement.pause()) */
    }
    loadImage(url){
        if(!url.includes(".png") && !url.includes(".jpg") && !url.includes(".jpeg")){
            this.throwError("no .png or .jpg file found at "+url)
            return;
        }
        //this.pdfDownloadLink.href = url
        //this.PDFPopup.open()
        window.open(url, '_blank');
        /* this.iframePopup.src = url
        this.MediaPopup.open() */
    }
    loadDownload(url){
        if(!url){
            this.throwError("no downloadable file found at "+url)
            return;
        }
        window.open(url, '_blank');
    }
    throwError(error){
        console.log(error)
        this.textPopupTitleContainer.innerHTML = "error"
        this.textPopupContainer.innerHTML = error
        this.TextPopup.scrimClickAction=''
        this.TextPopup.open()
        //this.TextPopup.scrimClickAction='close'

    }
}