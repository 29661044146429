import {MDCFormField} from '@material/form-field';
import {MDCCheckbox} from '@material/checkbox';

export class FilterListItem{

    constructor(appManager, filterList, category){
        this.appManager = appManager
        this.filterList = filterList
        this.category = category

        this.baseElement
        this.countElement
        this.checkboxElement
        this.content = []

        this.initialise()
    }

    initialise(){
        this.baseElement = this.filterList.filterBaseElement.cloneNode(true)
        this.baseElement.classList.remove("hide")
        this.filterList.filterListElement.appendChild(this.baseElement)
        this.formField = new MDCFormField(this.baseElement);
        this.baseElement.childNodes.forEach((child) => {
            if(child.innerHTML === "name")
                child.innerHTML = this.category
            else if(child.innerHTML === "count")
                this.countElement = child
            else if(child.id === "checkbox"){
                this.checkbox = new MDCCheckbox(child);
                this.checkboxElement = child
            }
        })
        this.countElement.innerHTML = "0"
        this.formField.input = this.checkbox;
        this.formField.input.checked = !this.formField.input.checked;
        this.checkboxElement.onclick = () => this.setContentVisibility(this.checkbox.foundation.adapter.isChecked())   
    }

    addContent(content){
        this.content.push(content)
        this.updateDisplayedCount()
    }

    updateDisplayedCount(){
        this.countElement.innerHTML = this.content.length
    }

    setContentVisibility(value){
        this.content.forEach(content => {
            if(content.hotspotMesh != null){
                content.hotspotMesh.setEnabled(value)
                content.hotspot.isActive = value
            }
        })
    }
}
