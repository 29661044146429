import { LiveDataWidget } from "./liveDataWidget";
import {MDCDialog} from '@material/dialog';

export class LiveDataConnection{

    constructor(appManager, url, key, pollingRate, liveDataWidget, name){
        this.appManager = appManager
        this.name = name
        this.url = url
        this.key = key
        this.pollingRate = pollingRate*1000
        this.isActive = false
        this.liveDataWidget = liveDataWidget
        this.liveDataWidget.liveDataConnection = this

        this.initialise()
        //this.openLiveDataModal(url, key, pollingRate)
        this.triggerPollingTimer()

    }

    initialise(){
        this.TextPopup = new MDCDialog(document.getElementById('TextPopup'))
        this.TextPopup.scrimClickAction=''
        this.TextPopup.listen('MDCDialog:opened',()=> this.TextPopup.scrimClickAction='close')
        this.TextPopup.listen('MDCDialog:closed',()=> this.TextPopup.scrimClickAction='')
        this.textPopupContainer = document.getElementById('textPopupContainer')
        this.textPopupTitleContainer = document.getElementById('textPopupTitleContainer')
    }


    openLiveDataModal(){
        this.textPopupTitleContainer.innerHTML = this.name
        this.textPopupContainer.innerHTML = "Fetching data every "+this.pollingRate/1000+" seconds..."
        this.TextPopup.scrimClickAction=''
        this.TextPopup.open()
        this.isActive = true

        //this.liveDataWidget.textPopupContainer = this.textPopupContainer
        //this.TextPopup.scrimClickAction='close'
        //this.displayLiveData()

        this.TextPopup.listen('MDCDialog:closed',() => {
            this.isActive = false
        })
    }

    displayLiveData(){
        this.isActive = true
        this.triggerPollingTimer()
    }

    triggerPollingTimer(){
        let t = setInterval(() => {
            this.fetchData()
            /* if(!this.isActive) clearInterval(t)  */
        },this.pollingRate)

    }

    fetchData(){
        this.data = "fetching"
        /* fetch(this.url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        }) */
        if(!this.url ){
            this.lastFetchedTime = "Failed to fetch due to no URL";
            this.updateDisplayText("No URL")
            return 
        } else if(!this.key ){
            this.lastFetchedTime = "Failed to fetch due to no KEY";
            this.updateDisplayText("No KEY")
            return 
        } 
        
        if(this.url.includes("piwebapi") ){
            fetch(this.url, {
                method: "GET",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    "Authorization": "Basic WFJBZG9wdFZpZXdlcjpUYWtlZGEtMTIz"}
            })
            .then(response => response.json())
            .then(response => {
                this.lastFetchedTime = ""
                this.updateDisplayText(response[this.key])
            })
        } else{
            fetch(this.url, {
                method: "GET",
                headers: {
                    'Accept': 'application/json'}
            })
            .then(response => response.json())
            .then(response => {
                this.lastFetchedTime = ""
                this.updateDisplayText(response[this.key])
            })
        } 
    }

    updateDisplayText(data){
        this.value = JSON.stringify(data)
        if(this.isActive)
            this.textPopupContainer.innerHTML = this.value + "<br>" + "Units: "+ this.liveDataWidget.units.text+"<br>"+"<br>" +this.lastFetchedTime
        this.liveDataWidget.updateValue(this.value)
        //this.liveDataWidget.updateTitle(this.key)
    }
}