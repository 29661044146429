export class RaycastSelector{  

    constructor(appManager){
        this.appManager = appManager
        this.currentSelected;

        this.intialise()
    }

    intialise(){
        var highlightLayer = new BABYLON.HighlightLayer("highlightLayer01", this.appManager.scene);
        highlightLayer.blurHorizontalSize = 0.3
        highlightLayer.blurVerticalSize = 0.3
        highlightLayer.innerGlow = false;
        highlightLayer.outerGlow = true;
        this.highlightLayer = highlightLayer
        
        this.appManager.scene.onPointerPick = (evt, pickInfo) => {
            if (pickInfo.hit) {
                if(pickInfo.pickedMesh.id == "hotspotMesh") return;

                let mesh = pickInfo.pickedMesh;
                function checkIfMeshRoot(parent){
                    if (typeof parent.metadata === 'string' || parent.metadata instanceof String){
                        if(parent.metadata.includes("mesh_")){
                            return false
                        }
                    }
                    return true
                }

                while (checkIfMeshRoot(mesh)) {
                    mesh = mesh.parent
                }

                if(mesh.metadata != null){
                    this.highlightLayer.removeAllMeshes()
                    if(this.currentSelected == mesh.metadata){
                        this.currentSelected = null
                        this.appManager.animationControls.resetAnimation(null)
                        return
                    }
                    
                    this.currentSelected = mesh.metadata
                    this.appManager.modelManager.models.forEach((m) =>{
                        if(mesh.metadata == m.id){
                            this.appManager.animationControls.resetAnimation(m.animationGroups[0])
                            m.models.meshes.forEach((x) => {
                                try{
                                    this.highlightLayer.addMesh(x, BABYLON.Color3.White());
                                }catch{
                                    console.log()
                                }
                            })
                        }
                    }) 
                }
            }
        }
    }
}