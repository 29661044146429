//import * as GUI from 'babylonjs-gui';
import { CameraManager } from "./cameraManager"
import { ModelManager } from "./modelManager"
import { ContentManager } from "./contentManager"
import { LightManager } from "./lightManager"
import { Options } from "../Objects/options"
import { ContentDrawer } from "../Objects/contentDrawer"
import { QRScanner } from "../Objects/qrScanner"
//import { XRManager } from "./xrManager"
//import { XRManagerBlippar } from "./xrManagerBlippar"
//import { VRManager } from "./vrManager"
import { MediaPlayer } from "../Objects/mediaPlayer"
import { AnimationControls } from "../Objects/animationControls"
import { Grid } from "../Objects/grid"
import { BabylonGrid } from "../babylon.gridMaterial.min"
import { RaycastSelector } from "../Objects/raycastSelector"
import { FilterList } from "../Objects/filterList"
import { HotspotManager } from "./hotspotManager"


export class AppManager{  

    constructor(engine, scene, data, htmlElements, appSettings){
        this.engine = engine
        this.data = data
        this.appSettings = appSettings
        this.scene = scene
        this.htmlElements = htmlElements
        
        this.cameraManager = new CameraManager(this)
        this.qrScanner = new QRScanner(this)
        this.modelManager = new ModelManager(this)
        this.filterList = new FilterList(this)
        this.contentManager = new ContentManager(this)
        this.contentDrawer = new ContentDrawer(this)
        this.lightManager = new LightManager(this)
        this.options = new Options(this)
        this.mediaPlayer = new MediaPlayer(this)
        this.animationControls = new AnimationControls(this)
        //this.xrManager = new XRManager(this)
        //this.xrManagerBlippar = new XRManagerBlippar(this)
        //this.vrManager = new VRManager(this)
        this.raycastSelector = new RaycastSelector(this)
        this.grid = new Grid(this)
        this.hotspotManager = new HotspotManager(this)
        
        /* this.scene.debugLayer.show({
            embedMode: true,
          }); */
    }
}