const CAMERA_RADIUS = 3
const CAMERA_RADIUS_MIN = 0.3
const CAMERA_RADIUS_MAX = 7

export class CameraManager{  
    constructor(appManager){
        this.appManager = appManager
        this.cameraTarget = BABYLON.Vector3.Zero()
        this.camera = this.createArcCamera()
        this.activePoints = 0
    }

    createArcCamera() {
        let camera = new BABYLON.ArcRotateCamera("camera", 3 * Math.PI / 4, Math.PI / 4, 4, BABYLON.Vector3.Zero(), this.appManager.scene)
        camera.minZ = 0.01
        camera.allowUpsideDown = false
        camera.wheelPrecision = 150
        camera.pinchDeltaPercentage = 0.001
        camera.useNaturalPinchZoom = false
        camera.pinchPrecision = 0.8111
        camera.panningSensibility = 1800
        //camera.panningDistanceLimit = 1
        //this.raycastDistanceLimit = 1
        camera.panningInertia = 0.5
        //camera.mapPanning = false
        camera.radius = CAMERA_RADIUS
        //camera.lowerRadiusLimit = CAMERA_RADIUS_MIN
        //camera.upperRadiusLimit = CAMERA_RADIUS_MAX
        camera.attachControl(this.appManager.htmlElements.canvas, true)
        camera.rebuildAnglesAndRadius()
        this.setUpCameraAutoRotation(camera)
        this.setUpPanning(camera)

        var prevRadius = camera.radius;
        this.appManager.scene.beforeRender = () => {    
            let ratio = 1;
            if (prevRadius != camera.radius) {
                ratio = prevRadius / camera.radius;
                prevRadius = camera.radius;
    
                camera.panningSensibility *= ratio;
                camera.wheelPrecision *= ratio;
            }
        }; 
        
        return camera;
    }

    setUpCameraAutoRotation(camera){
        camera.useAutoRotationBehavior = true
        camera.autoRotationBehavior.idleRotationSpeed = 0.1
        camera.autoRotationBehavior.idleRotationWaitTime = 2000
        camera.autoRotationBehavior.idleRotationSpinupTime = 2000
        camera.autoRotationBehavior.zoomStopsAnimation = true
        camera.useAutoRotationBehavior = false

    }

    setUpPanning(camera){
        var advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
        var rect1 = new BABYLON.GUI.Ellipse();
        rect1.width = "5px";
        rect1.height = "5px";
        rect1.color = "white"
        rect1.isVisible = false;
        advancedTexture.addControl(rect1); 

        this.appManager.scene.onPointerObservable.add((eventData) => {
            if ((eventData.event.button === 2 && eventData.event.pointerType === "mouse") ||
                eventData.event.ctrlKey) {
                    this.castRay()
                    rect1.isVisible = false;
                } 

            if ((eventData.event.buttons === 0 && eventData.event.pointerType === "touch" && this.activePoints > 1)) {
                    this.castRay()
                    rect1.isVisible = false;
                } 
            this.activePoints--
        }, BABYLON.PointerEventTypes.POINTERUP);

        this.appManager.scene.onPointerObservable.add((eventData) => {
            if ((eventData.event.button === 2 && eventData.event.pointerType === "mouse") ||
                (eventData.event.buttons === 1 && eventData.event.pointerType === "touch") ||
                eventData.event.ctrlKey) {
                    rect1.isVisible = true;
                    this.activePoints++
                } 
        }, BABYLON.PointerEventTypes.POINTERDOWN);
    }

    vecToLocal(vector, mesh){
        var m = mesh.getWorldMatrix();
        var v = BABYLON.Vector3.TransformCoordinates(vector, m);
        return v;		 
    }

    castRay(){       
        var origin = this.camera.position;
    
        var forward = new BABYLON.Vector3(0,0,1);		
        forward = this.vecToLocal(forward, this.camera);
    
        var direction = forward.subtract(origin);
        direction = BABYLON.Vector3.Normalize(direction);
    
        var length = 20;
    
        var ray = new BABYLON.Ray(origin, direction, length);
        function predicate(mesh) {
            if(mesh.name){
                if (mesh.name == "hotspotMesh" || mesh.name == "plane"|| mesh.name.includes("teleportation")|| mesh.name.includes("Teleportation") || mesh.name == "rotationCone") {
                    return false;
                }
            }
            return true;
        }

        var hit = this.appManager.scene.pickWithRay(ray, predicate)

        if (hit.pickedPoint != null){
            if(hit.pickedMesh.name == "hotspotMesh" || BABYLON.Vector3.Distance(BABYLON.Vector3.Zero(),hit.pickedPoint) > this.raycastDistanceLimit)
                return
            this.cameraTarget = hit.pickedPoint
            this.appManager.grid.pivotGizmo.position = hit.pickedPoint
            console.log(hit.pickedMesh.name)

            this.camera.setTarget(this.cameraTarget)
        }
    }

    updateCameraLimits(value){
        value = value*3
        //this.raycastDistanceLimit = value
        //this.camera.panningDistanceLimit = value
        //this.camera.upperRadiusLimit = 7*value

        /* if(this.cameraTarget.position == undefined)
            return
        if(this.cameraTarget.position.length > value){
            this.cameraTarget.position.scale(1-(this.cameraTarget.position.length-value))
            this.camera.setTarget(this.cameraTarget)
        } */
            
    }
}