import {Html5QrcodeScanner} from "html5-qrcode"
import {Html5QrcodeScanType} from "html5-qrcode"
import {MDCDialog} from '@material/dialog';
import { Content } from "./content";

export class QRScanner{

    constructor(appManager){
        this.appManager = appManager
        this.html5QrcodeScanner = null
        this.resultOptions = []

        this.setUpScanButton()
    }

    initialise(){
        let self = this
        function onScanSuccess(decodedText, decodedResult) {

            console.log(`Scan result: ${decodedText}`, decodedResult)
            self.qrScannerPopup.close()
            if(`${decodedText}`.includes("api/experiences/")){
                //self.qrResultPopup.open()
                self.checkResult(`${decodedText}`)//`http://10.100.200.16:5100/api/experiences/1`)
            }else{
                this.appManager.htmlElements.snackBar.open(); 
                this.appManager.htmlElements.snackBar.labelText = "Incorrect api request used."
            }
        }

        let config = {
            fps: 10,
            qrbox: {width: 200, height: 200},
            rememberLastUsedCamera: false,
            supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA, Html5QrcodeScanType.SCAN_TYPE_FILE]
          };
 
        this.html5QrcodeScanner = new Html5QrcodeScanner("reader", config, false);
            
        this.html5QrcodeScanner.render(onScanSuccess)
        document.getElementById("reader").childNodes[0].classList.add("hide")
    }

    setUpScanButton(){
        this.qrScannerPopup = new MDCDialog(document.getElementById('qrScannerPopup'))
        this.qrScannerButton = document.getElementById("scan-qr-code-btn")
        this.qrScannerButton.onclick = () => /* this.debugCheckResult("http://10.100.200.16:5100/api/experiences/1")// */this.qrScannerPopup.open()

        this.qrScannerPopup.listen('MDCDialog:closed',()=> this.clearQRScanner())
        this.qrScannerPopup.listen('MDCDialog:opening',()=> this.initialise())
    }

    clearQRScanner(){ 
        let node = document.getElementById("reader")
        while (node.hasChildNodes()) {
            node.removeChild(node.lastChild);
        }
        this.html5QrcodeScanner.clear()
    }

    checkResult(path){
        if(this.appManager.contentDrawer.contentOptions.length >0){
            this.appManager.contentDrawer.clearAllOptions()
        }
        if(this.appManager.contentManager.content.length >0){
            this.appManager.contentManager.disposeContent()
        }
        fetch(path)
            .then(response => response.json()) 
            .then(data => {
                this.appManager.contentManager.newData = data
                this.appManager.contentManager.showImportList()
                let myArray = path.split("experiences/")
                window.history.pushState("", "", '/?id='+myArray[1]);

                /* this.appManager.modelManager.modelPaths = []

                data.content.forEach(content => {
                    this.appManager.contentManager.content.push(new Content(this.appManager, content))
                    if(content.url)
                        if(content.url.includes(".glb"))
                            this.appManager.modelManager.modelPaths.push(content)
                }) *///this.appManager.contentDrawer.addContentOption(content))
            })      
            /* .then(data => {
                this.appManager.hotspotManager.createHotspots()
                this.appManager.modelManager.loadModels()
                document.getElementById("loadingScreen").style.display = "none"
            }) */
    }
}