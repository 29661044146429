import { Content } from "../Objects/content.js";
import {MDCDialog} from '@material/dialog';

export class ContentManager{  

    constructor(appManager){
        this.appManager = appManager
        this.content = []
        this.newData
        this.importListItems = []
        this.importListItem = document.getElementById('importListItem')
        this.importListElement = document.getElementById('importList')
        this.packageNameElement = document.getElementById('packageName')

        this.initialise()
    }

    initialise(){
        this.importPopup = document.getElementById('importPopup');
        this.importDialog = new MDCDialog(this.importPopup);
        this.importDialog.listen('MDCDialog:closed', async (e) =>  {
            if(e.detail.action != "loadAll") return
            await this.pushNewContent()
            this.loadNewContent()
            this.packageNameElement.innerHTML = "Experience ID: "+this.newData.experienceId
        })

    }

    showImportList(){
        this.importListItems.forEach(element => {
            element.remove()
            element = null
        })
        this.importListItems = []
        this.newData.content.forEach(content => {
            let element = this.importListItem.cloneNode(true)
            element.classList.remove("hide")
            this.importListElement.appendChild(element)
            element.childNodes.forEach((child) => {
                if(child.innerHTML === "name"){
                    child.innerHTML = content.name
                }
                else if(child.innerHTML === "id"){
                    child.innerHTML = content.contentId+":"
                }
            })
            this.importListItems.push(element)
        })

        this.importDialog.open()
        document.getElementById("loadingScreen").style.display = "none"
    }

    async pushNewContent(){
        this.appManager.modelManager.modelPaths = []

        this.newData.content.forEach(content => {
            let contentObj = new Content(this.appManager, content)
            this.content.push(contentObj)
            if(contentObj.url)
                if(contentObj.fileTypeId == 1)//if(contentObj.url.includes(".glb"))
                    this.appManager.modelManager.modelPaths.push(contentObj)
        })
    }

    loadNewContent(){
        this.appManager.hotspotManager.createHotspots()
        this.appManager.modelManager.loadModels()
    }

    parentContent(){
        this.content.forEach(async (content) => {
            await content.parentContent()
        })
    }

    findContentById(id){
        let result = null
        this.content.forEach((content) => {
            if(content.contentId == id){
                result = content
                return
            }
        })
        return result
    }

    disposeContent(){
        this.content.forEach((content) => {
            content.dispose()
        })
        this.content = []
    }
}