export class LiveDataWidget{

    constructor(appManager, hotspot){
        this.appManager = appManager
        this.hotspot = hotspot
        this.liveDataConnection 

        //this.textPopupContainer = null

        this.initialise()
        this.createLiveDataDisplay()
        //this.openLiveDataModal(url, key, pollingRate)
    }

    initialise(){
        
    }

    createLiveDataDisplay(){
        //this.display = this.createDisplayGUI("title");
        this.displayMesh = this.createDisplayMesh();
        this.displayMesh.parent = this.hotspot.content.root
    }

    async createDisplayMesh(){
        let displayMesh = BABYLON.MeshBuilder.CreatePlane("hotspotMesh", {width:1, height:1}, this.appManager.scene);
        displayMesh.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
        displayMesh.position.x = this.hotspot.position.x;//this.position.x;
        displayMesh.position.y = this.hotspot.position.y;//this.position.y;
        displayMesh.position.z = this.hotspot.position.z;//this.position.z;
        displayMesh.scaling.x = this.hotspot.hotspotScaledSize
        displayMesh.scaling.y = this.hotspot.hotspotScaledSize

        let displayADT = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(displayMesh);
        //let loadedGUI = await displayADT.parseFromSnippetAsync("#YSUHV2#17");
        let loadedGUI = await displayADT.parseFromURLAsync("/textures/icons/liveDataWidget.json")
        
        //displayADT.renderScale = 1;
        displayADT.addControl(loadedGUI);
        displayADT.idealWidth = 1000;  
        displayADT.idealHeight = 600;

        this.title = displayADT.getControlByName("Textblock_Title");
        this.title.text = this.hotspot.content.name
        this.value = displayADT.getControlByName("Textblock_Value");
        this.value.text = "value"
        this.units = displayADT.getControlByName("Textblock_Units");
        this.units.text = this.hotspot.content.units


        this.liveDataRectangle = displayADT.getControlByName("Rectangle_LiveData");
        //displayMesh.isPickable = false;  
        this.appManager.raycastSelector.highlightLayer.addExcludedMesh(displayMesh)

        this.liveDataRectangle.onPointerClickObservable.add(() => {
            this.liveDataConnection.openLiveDataModal()
            //this.appManager.mediaPlayer.loadMedia(this.hotspot.content);
        })

        return displayMesh
    }

    updateValue(value){
        this.value.text = value
        //if(this.textPopupContainer)
            //this.textPopupContainer.innerHTML = this.value
    }
} 