import { Content } from "../Objects/content";
import { Model } from "../Objects/model";

export class ModelManager{  

    constructor(appManager){
        this.appManager = appManager
        this.modelPaths = [
            //new Content(this.appManager,{url:"/textures/models/puh.glb", contentTypeId: 1, contentId:1, name:"puh.glb","contentId":1,"transform":{"positionX":0,"positionY":0,"positionZ":0,"rotationX":0,"rotationY":0,"rotationZ":0}}),
            //new Content(this.appManager,{url:"/textures/models/puh.glb", contentTypeId: 1, contentId:1, name:"puh.glb","contentId":2,"transform":{"positionX":0,"positionY":1,"positionZ":0,"rotationX":0,"rotationY":0,"rotationZ":0}})
        ]
        this.models = []
        this.root = new BABYLON.TransformNode("modelRoot");
        this.loadedModelsCount = 0

        //this.loadModels()
    }

    async loadNewModel(content){
        await this.disposeModels()
        this.modelPaths = [content]
        this.loadModels()
    }

    async loadModels(){
        await this.disposeModels()
        let self = this

        this.modelPaths.forEach(function(m) {
            console.log(m)
            self.models.push(new Model(self.appManager, m))
        });
    }

    checkIfAllLoaded(){
        this.loadedModelsCount++
        if(this.modelPaths.length <= this.loadedModelsCount)
            this.appManager.contentManager.parentContent()
    }

    async disposeModels(){
        this.models.forEach(function(m) {
            m.disposeModel()
        });
        this.models = []
    }

    toggleGizmoz(){
        this.models.forEach(function(m) {
            m.toggleGizmo()
        });
    }
}