import { FilterListItem } from "./filterListItem"

export class FilterList{

    constructor(appManager){
        this.appManager = appManager
        this.filterBaseElement
        this.categories = ["warning","critical","default"]//,"image","video","text","pdf","3d","links","audio"]
        this.filterElements = []

        this.initialise()
    }

    initialise(){
        this.filterListElement = document.getElementById('filterListElement')
        this.filterBaseElement = document.getElementById('contentOptionFilter')
        this.categories.forEach(category => {
            this.filterElements.push(new FilterListItem(this.appManager, this, category))
        })
    }

    addFilterItem(content){
        this.checkContentType(content)
    }

    checkContentType(content){
        if(content.url){
            if(content.url.includes(".glb"))
                return
        }
        if(content.isPositioned == false)
            return

        if(content.severityId == 2)
            this.addFilterItemToList(content, "warning")
        else if(content.severityId == 3)
            this.addFilterItemToList(content, "critical")
        else if(content.severityId == 1 || content.severityId == 0)
            this.addFilterItemToList(content, "default")
            
        /* if(content.contentTypeId == 2)
            this.addFilterItemToList(content, "text")
        else if(content.url.includes('.png'))
            this.addFilterItemToList(content, "image")
        else if(content.url.includes('.mp4'))
            this.addFilterItemToList(content, "video")
        else if(content.url.includes('.pdf'))
            this.addFilterItemToList(content, "pdf")
        else if(content.url.includes('.glb'))
            this.addFilterItemToList(content, "3d") */
        /* else if(content.severityId = 2)
            this.addFilterItemToList(content, "links")
        else if(content.severityId = 2)
            this.addFilterItemToList(content, "audio") */
    }

    addFilterItemToList(content, category){
        this.filterElements.forEach(filterElement => {
            if(filterElement.category == category)
                filterElement.addContent(content)
        })
    }
}
