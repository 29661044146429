export class Grid{  

    constructor(appManager){
        this.appManager = appManager
        this.root
        this.pivotGizmo

        this.setupGrid()
    }

    setupGrid(){
        let groundMaterial = new BABYLON.GridMaterial("groundMaterial", this.appManager.scene);
        groundMaterial.majorUnitFrequency = 16;
        groundMaterial.minorUnitVisibility = 0.45;
        groundMaterial.gridRatio = 1;
        groundMaterial.backFaceCulling = false;
        groundMaterial.mainColor = new BABYLON.Color3(1, 1, 1);
        groundMaterial.lineColor = new BABYLON.Color3(1.0, 1.0, 1.0);
        groundMaterial.opacity = 0.9;
        let groundMaterial1 = new BABYLON.GridMaterial("groundMaterial", this.appManager.scene);
        groundMaterial1.majorUnitFrequency = 16;
        groundMaterial1.minorUnitVisibility = 0.45;
        groundMaterial1.gridRatio = 1;
        groundMaterial1.backFaceCulling = false;
        groundMaterial1.mainColor = new BABYLON.Color3(0.9, 0.9, 0.9);
        groundMaterial1.lineColor = new BABYLON.Color3(0.9,0.9, 0.9);
        groundMaterial1.opacity = 0.5;

        const plane = BABYLON.MeshBuilder.CreatePlane("plane", {height:1, width: 1, sideOrientation: BABYLON.Mesh.DOUBLESIDE}, this.appManager.scene);
        plane.material = groundMaterial; 
        const planeX = BABYLON.MeshBuilder.CreatePlane("plane", {height:10, width: 10, sideOrientation: BABYLON.Mesh.DOUBLESIDE}, this.appManager.scene);
        planeX.material = groundMaterial;
        planeX.rotation.x  =  Math.PI/2;
        const planeY = BABYLON.MeshBuilder.CreatePlane("plane", {height:1, width: 1, sideOrientation: BABYLON.Mesh.DOUBLESIDE}, this.appManager.scene);
        planeY.material = groundMaterial;
        planeY.rotation.y  =  Math.PI/2;

        const plane1 = BABYLON.MeshBuilder.CreatePlane("plane", {height:0.1, width: 0.1, sideOrientation: BABYLON.Mesh.DOUBLESIDE}, this.appManager.scene);
        plane1.material = groundMaterial1; 
        const planeX1 = BABYLON.MeshBuilder.CreatePlane("plane", {height:0.1, width: 0.1, sideOrientation: BABYLON.Mesh.DOUBLESIDE}, this.appManager.scene);
        planeX1.material = groundMaterial1;
        planeX1.rotation.x  =  Math.PI/2;
        const planeY1 = BABYLON.MeshBuilder.CreatePlane("plane", {height:0.1, width: 0.1, sideOrientation: BABYLON.Mesh.DOUBLESIDE}, this.appManager.scene);
        planeY1.material = groundMaterial1;
        planeY1.rotation.y  =  Math.PI/2;

        this.root = plane      
        planeX.parent = this.root        
        planeY.parent = this.root

        this.pivotGizmo = plane1      
        planeX1.parent = this.pivotGizmo        
        planeY1.parent = this.pivotGizmo
        
        this.appManager.raycastSelector.highlightLayer.addExcludedMesh(plane);
        this.appManager.raycastSelector.highlightLayer.addExcludedMesh(planeX);
        this.appManager.raycastSelector.highlightLayer.addExcludedMesh(planeY);
        this.appManager.raycastSelector.highlightLayer.addExcludedMesh(this.root);
        plane.isPickable = false;  
        planeX.isPickable = false;  
        planeY.isPickable = false; 
        this.appManager.raycastSelector.highlightLayer.addExcludedMesh(plane1);
        this.appManager.raycastSelector.highlightLayer.addExcludedMesh(planeX1);
        this.appManager.raycastSelector.highlightLayer.addExcludedMesh(planeY1);
        this.appManager.raycastSelector.highlightLayer.addExcludedMesh(this.pivotGizmo);
        plane1.isPickable = false;  
        planeX1.isPickable = false;  
        planeY1.isPickable = false;   
        //this.root = false;  
        //this.appManager.raycastSelector.highlightLayer.addExcludedMesh(groundMaterial);

        // /this.pivotGizmo = this.root.clone("i")
        
    }
    toggleGrid(){
        this.root.setEnabled(!this.root.isEnabled())
    }
}