export class ContentOption{

    constructor(appManager, content, baseElement, listElement){
        this.appManager = appManager
        this.content = content
        this.baseElement = baseElement
        this.listElement = listElement
        this.element 
        this.initialise()
    }

    initialise(){
        this.createElement()
    }

    createElement(){
        this.element = this.baseElement.cloneNode(true)
        this.element.classList.remove("hide")
        this.listElement.appendChild(this.element)
        this.element.childNodes.forEach((child) => {
            if(child.innerHTML === "name"){
                if(this.content.name.includes('resources/')){
                    var fields = this.content.name.split('resources/');
                    fields = fields[1].split('?raw=true')
                    child.innerHTML = fields[0].replace(/%20/g, " ");
                }else{
                    child.innerHTML = this.content.name
                }
            }
        })
        this.element.onclick = () => {this.appManager.mediaPlayer.loadMedia(this.content); this.appManager.contentDrawer.drawer.open = false}
    }

    disposeElement(){
        this.content = null
        this.contentOptionsList = null
        this.baseElement = null
        this.element.innerHTML = ""
        this.element.remove()
    }
}