import { Hotspot } from "../Objects/hotspot";

export class HotspotManager{  

    constructor(appManager){
        this.appManager = appManager
        this.hotspots = []
        this.trackHotspots = false

        this.createHotspots()
    }

    createHotspots(){
        this.deleteHotspots();
        this.appManager.contentManager.content.forEach(content => {
            if(content.contentTypeId == 1 && content.url)
                if(content.url.includes(".glb"))
                    return;
            if(content.isPositioned == false)
                return;
            let hotspot = new Hotspot(this,content)
            content.hotspot = hotspot
            this.hotspots.push(hotspot)
        })  
    }

    trackHotspotPositions(){
        var canvasZone = document.getElementById('renderCanvas');
        var renderer = this.productManager.appManager.scene.enableDepthRenderer(this.productManager.appManager.cameraManager.camera, false); 
        var depthMap = renderer.getDepthMap();
        var buffer = new Float32Array(4 * depthMap.getSize().width * depthMap.getSize().height);

        this.productManager.appManager.scene.onAfterRenderObservable.add(() => {
            depthMap.readPixels(0, 0, buffer);
    
            this.hotspots.forEach((hotspot) => {
                var posInView = BABYLON.Vector3.TransformCoordinates(hotspot.hotspotObject.position, this.productManager.appManager.scene.getViewMatrix());
                var posInViewProj = BABYLON.Vector3.TransformCoordinates(hotspot.hotspotObject.position, this.productManager.appManager.scene.getTransformMatrix());
                var screenCoords = posInViewProj.multiplyByFloats(0.5, -0.5, 1.0).add(new BABYLON.Vector3(0.5, 0.5, 0.0)).
                                        multiplyByFloats(this.productManager.appManager.engine.getRenderWidth(), this.productManager.appManager.engine.getRenderHeight() , 1);
    
                var px = screenCoords.x - 20;
                var py = screenCoords.y - 20;
    
                hotspot.element.style.left = (px + canvasZone.offsetLeft) + 'px';
                hotspot.element.style.top = (py + canvasZone.offsetTop) + 'px';
                
                px = Math.floor(px + 20);
                py = Math.floor(this.productManager.appManager.engine.getRenderingCanvas().clientHeight/* .getRenderHeight() */ - (py + 20));
    
                var zInZBuff = buffer[4 * (px + py * depthMap.getSize().width)];
    
                var z = (posInView.z - this.productManager.appManager.cameraManager.camera.minZ) / (-this.productManager.appManager.cameraManager.camera.minZ + this.productManager.appManager.cameraManager.camera.maxZ);
    
                if (z > zInZBuff) {
                    hotspot.element.style.opacity = 0.4;
                    hotspot.element.classList.add("blur")
                } else {
                    hotspot.element.style.opacity = 1.0;
                    hotspot.element.classList.remove("blur")
                }
            });
        });
    }

    toggleHotspots(){
        this.hotspots.forEach((hotspot) => {
            if(!hotspot.isActive)
                return
            if(hotspot.hotspotMesh.isEnabled())
                hotspot.setEnabled(false)
            else
                hotspot.setEnabled(true)
        })
    }
    setHotspotsEnabled(value){
        this.hotspots.forEach((hotspot) => {
            if(!hotspot.isActive)
                return
                
            hotspot.setEnabled(value)
        })
    }

    //TODO
    deleteHotspots(){
        this.hotspots.forEach((hotspot) => {
            hotspot.dispose()
        })
        this.hotspots = []
    }
}