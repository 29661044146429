import {MDCDrawer} from "@material/drawer";
import {MDCTabBar} from '@material/tab-bar';
import {MDCRipple} from '@material/ripple';
import { ContentOption } from "./contentOption"
import { Content } from "./content";
import * as QueryString from 'query-string';


export class ContentDrawer{

    constructor(appManager){
        this.appManager = appManager
        this.baseElement3D = document.getElementById('contentOption3D')
        this.baseElement2D = document.getElementById('contentOption2D')
        this.contentOptions = []

        this.initialise()
    }

    initialise(){
        const drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'))
        this.drawer = drawer
        this.menuButton = document.getElementById("menu-btn")
        this.menuButton.addEventListener('click', (event) => {
            drawer.open = true
        })
        this.setUpTabs()
        //this.setUpLoadAllButton()
        //this.setUpClearAllButton()
    }

    setUpTabs(){
        this.contentList3D = document.getElementById('contentList3D')
        this.contentList2D = document.getElementById('contentList2D')
        this.contentListFilter = document.getElementById('contentListFilter')
        this.tabBar = new MDCTabBar(document.querySelector('.mdc-tab-bar'))

        //document.getElementById('tab3D').onclick = () => this.openTab3D(true); 
        document.getElementById('tab2D').onclick = () => this.openTab3D(false); 
        document.getElementById('tabFilter').onclick = () => this.openTab3D(true); 
        this.appManager.contentDrawer = this

        const query = QueryString.parse(location.search);
        console.log(this.appManager.appSettings.baseUrl+ query.id)
        if(query.id)
            this.appManager.qrScanner.checkResult(this.appManager.appSettings.baseUrl+ query.id)
        else{
            this.appManager.contentManager.content.push(new Content(this.appManager,{url:"/textures/models/puh.glb", contentTypeId: 1, contentId:1, name:"puh.glb","transform":{"positionX":0,"positionY":0,"positionZ":0,"rotationX":0,"rotationY":0,"rotationZ":0,"scaleX":1,"scaleY":1,"scaleZ":1}}))
            this.appManager.contentManager.content.push(new Content(this.appManager,{url:"/textures/Manual.pdf", contentTypeId: 1, contentId:2, severityId:2,markerSizeId:2,name:"Manual.pdf","transform":{"positionX":1,"positionY":0.5,"positionZ":0.3,"rotationX":0,"rotationY":0,"rotationZ":0}}))
            this.appManager.contentManager.content.push(new Content(this.appManager,{url:"", contentTypeId: 1, contentId:3, severityId:3, markerSizeId:4,name:"dromoneLogo.png","transform":{"positionX":0,"positionY":0.3,"positionZ":-0.4,"rotationX":0,"rotationY":0,"rotationZ":0}}))
            this.appManager.contentManager.content.push(new Content(this.appManager,{url:"/textures/dromoneVideo.mp4", contentTypeId: 1, contentId:4, severityId:2,markerSizeId:2,name:"dromoneVideo.mp4","transform":{"positionX":1,"positionY":1,"positionZ":0,"rotationX":0,"rotationY":0,"rotationZ":0}}))
            this.appManager.contentManager.content.push(new Content(this.appManager,{message:"", contentTypeId: 2, contentId:5, severityId:1,markerSizeId:2,name:"","transform":{"positionX":1.2,"positionY":-0.5,"positionZ":-0.3,"rotationX":0,"rotationY":0,"rotationZ":0}}))
            this.appManager.modelManager.modelPaths.push(this.appManager.contentManager.content[0])
            this.appManager.modelManager.loadModels()
        }
        this.tabBar.activateTab(0);

    }

    openTab3D(value){
        if(value){
            this.contentListFilter.classList.remove("hide")
            this.contentList2D.classList.add("hide")
            this.tabBar.activateTab(1);
        }else{
            this.contentListFilter.classList.add("hide")
            this.contentList2D.classList.remove("hide")
            this.tabBar.activateTab(0);
        }
    }

    addContentOption(content){
        if(content.contentTypeId == 1){
            if(content.fileTypeId == 1)
                console.log("3d")//this.contentOptions.push(new ContentOption(this.appManager, content, this.baseElement3D, this.contentList3D))
            else
                this.contentOptions.push(new ContentOption(this.appManager, content, this.baseElement2D, this.contentList2D))
        }else if(content.contentTypeId == 2){
            this.contentOptions.push(new ContentOption(this.appManager, content, this.baseElement2D, this.contentList2D))
        }else if(content.contentTypeId == 3){
            this.contentOptions.push(new ContentOption(this.appManager, content, this.baseElement2D, this.contentList2D))
        }
        
        if(this.loadAllButtonElement)
            this.checkIfToShowLoadAllButton()
    }

    clearAllOptions(){
        this.contentOptions.forEach(element => element.disposeElement())
        this.contentOptions = []
    }

    setUpClearAllButton(){
        this.clearAllButtonElement = document.getElementById('clearAllModelsButton')
        const clearAllButton = new MDCRipple(this.clearAllButtonElement);

        this.clearAllButtonElement.onclick = () => this.appManager.modelManager.disposeModels(); 
    }

    setUpLoadAllButton(){
        this.loadAllButtonElement = document.getElementById('loadAllModelsButton')
        const loadAllButton = new MDCRipple(this.loadAllButtonElement);

        this.loadAllButtonElement.onclick = () => this.loadAllModels(); 
    }
    loadAllModels(){
        this.contentOptions.forEach(contentOption => {
            if(contentOption.content.url.includes(".glb"))
                this.appManager.mediaPlayer.loadMedia(contentOption.content); 
        })
        this.drawer.open = false
    }
    checkIfToShowLoadAllButton(){
        var count = 0
        this.contentOptions.forEach(contentOption => {
            if(contentOption.content.contentTypeId == 1 && contentOption.content.url)
                if(contentOption.content.url.includes(".glb"))
                    count++
        })
        if(count>1)
            this.loadAllButtonElement.classList.remove("hide")
        else
            this.loadAllButtonElement.classList.add("hide")
    }
}