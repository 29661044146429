export class Model{  

    constructor(appManager, content){
        this.appManager = appManager
        this.content = content
        this.root
        this.animationGroups = []
        this.id = "mesh_"+content.contentId
        //this.animationControls = new AnimationControls(this)

        this.loadProductModel(content.url, false)
        this.setupGizmo()
    }

    async loadProductModel(modelPath, isUploadedFile){
        let models
        let self = this
        this.animationGroups = []
        var error = false
        console.log("load",modelPath)

        /* await fetch(modelPath)
            .then( res => res.blob())
            .then(blob => this.file = window.URL.createObjectURL(blob))
            .catch(err => error = true);
        
        if(error)return */
        //var blob = new Blob([plaintextbytes], { type: "model/gltf-binary" });
        //BABYLON.SceneLoader.ImportMeshAsync("",modelPath,"", this.appManager.scene) 
       /*  fetch(modelPath)           //api for the get request   
            .then(response => response.json())
            .then(data => console.log(data)); */
        /* if(isUploadedFile){ 
            models = await BABYLON.SceneLoader.ImportMeshAsync("","",modelPath)
        }
        else{ */
        //models = await BABYLON.SceneLoader.AppendAsync(this.file, undefined, this.appManager.scene, undefined, ".glb");
        //models = await BABYLON.SceneLoader.Append("", this.file, this.appManager.scene, function (scene) { 
            // do something with the scene
        //});
            models = await BABYLON.SceneLoader.ImportMeshAsync("",modelPath,"")
        ///}
        models.meshes[0].metadata = this.id

        if(models.animationGroups.length > 0){
            
            models.animationGroups.forEach(function(g) {
                //self.setUpAnimationGroup(g)
                //g.normalize(0,3000)
                g.pause()
            })
        }
        models.meshes[0].scaling = new BABYLON.Vector3(-this.content.transform.scaleX,this.content.transform.scaleY,this.content.transform.scaleZ)
        models.meshes[0].position = new BABYLON.Vector3(this.content.transform.positionX,this.content.transform.positionY,this.content.transform.positionZ)
        models.meshes[0].rotation = new BABYLON.Vector3(this.content.transform.rotationX * Math.PI / 180,this.content.transform.rotationY * Math.PI / 180,this.content.transform.rotationZ * Math.PI / 180)

        this.animationGroups = models.animationGroups
        this.root = models.meshes[0]// BABYLON.Mesh.MergeMeshes(models.meshes.filter(m => m.getTotalVertices() > 0), true, true);
        this.appManager.scene.stopAnimation(models.meshes[0])
        this.models = models 
        document.getElementById("loadingScreen").style.display = "none"
        this.product = models
        this.gizmo.attachedMesh = this.root;
        this.checkForCameras()
        this.toggleGizmo()
        this.content.model = this.root
        //this.root.scaling = new BABYLON.Vector3(3,3,3)
        this.root.parent = this.content.root
        this.appManager.modelManager.checkIfAllLoaded()
        //this.updateBoundingBox()
    }

    updateBoundingBox(){
        const getBoundingBox = (boundingBoxFn = () => ({ min:null, max:null })) => (meshes = []) => {
            const { min, max } = meshes.reduce(
              ({ min, max }, mesh) => {
                const { min: meshMin, max: meshMax } = boundingBoxFn(mesh);
                return {
                  min: BABYLON.Vector3.Minimize(min, meshMin),
                  max: BABYLON.Vector3.Maximize(max, meshMax),
                };
              },
              { min: BABYLON.Vector3.Zero(), max: BABYLON.Vector3.Zero() },
            );
            return new BABYLON.BoundingInfo(min, max);
          };

        const GOOD_getBoundingBox = getBoundingBox(mesh => mesh.getHierarchyBoundingVectors());

        const parent = this.models.meshes[0];
        const boundingBox = GOOD_getBoundingBox(this.models.meshes);
        parent.setBoundingInfo(boundingBox);
        //parent.showBoundingBox = true;  

        //console.log(parent.getBoundingInfo().boundingBox.maximum.length())
        this.appManager.cameraManager.updateCameraLimits(parent.getBoundingInfo().boundingBox.maximum.length())
    }

    disposeModel(){
        if(this.models){
            this.models.meshes.forEach(function(m) {
                m.dispose();
            });
        }
        this.product = null
        this.models = null
        this.root = null
        this.animationGroup = null
        this.gizmo.attachedMesh = null

        this.slider = null
        document.getElementById('bottomBar').classList.add("hide")
    }

    setupGizmo(){
        const utilLayer = new BABYLON.UtilityLayerRenderer(this.appManager.scene);

        this.gizmo = new BABYLON.PositionGizmo(utilLayer);

        // Keep the gizmo fixed to world rotation
        this.gizmo.updateGizmoRotationToMatchAttachedMesh = false;
        this.gizmo.updateGizmoPositionToMatchAttachedMesh = true;
    }
    toggleGizmo(){
        let parent = this.content.root
        this.gizmo.attachedMesh = !this.gizmo.attachedMesh ? parent : null
    }

    selectModel(){
        //if(hasAnimation){
            //show animation controls this.animationControls.resetAnimation(this.animationGroups[0])
        //}
        //else{
            //hide animation controls
        //}
    }

    checkForCameras(){
        console.log(this.content.url, this.appManager.scene.cameras)
    }
}