import {MDCSlider} from '@material/slider';
import {MDCRipple} from '@material/ripple';

export class AnimationControls{

    constructor(appManager){
        this.appManager = appManager
        this.isPlaying = false
        this.currentAnimationGroup

        this.initialise()
    }

    initialise(){
        this.animationSlider = new MDCSlider(document.getElementById('animationSlider'))
        this.animationPlayButton = new MDCRipple(document.getElementById('playButton'))
        this.animationPlayButtonElement = document.getElementById('playButton')
        this.animationPlayButtonIcon = document.getElementById('playButtonIcon')
        this.animationPlayButtonElement.onclick = () =>  this.toggleAnimation()
        document.getElementById('bottomBar').classList.add("hide")


        this.animationSlider.listen('MDCSlider:input',() => this.updateAnimationToSlider())
    }

    toggleAnimation(){
        if(this.isPlaying){
            this.pauseAnimation()
        }else{
            this.playAnimation()
        }
        
    }

    pauseAnimation(){
        this.animationPlayButtonIcon.innerHTML = "play_arrow"
        this.currentAnimationGroup.pause()
        this.isPlaying = false
        if (window.interval) {
            clearInterval(window.interval);
        }
        this.appManager.hotspotManager.setHotspotsEnabled(true)
        /* this.appManager.modelManager.models.forEach(model => {
            model.updateBoundingBox()
        }) */
    }
    playAnimation(){
        if(this.animationSlider.getValue() == 100)
            this.currentAnimationGroup.goToFrame(0)
        this.animationPlayButtonIcon.innerHTML = "pause"
        this.isPlaying = true
        this.currentAnimationGroup.play()
        this.updateSliderToAnimation()
        this.appManager.hotspotManager.setHotspotsEnabled(false)
    }

    resetAnimation(animationGroup){
        //if(animationGroup == null)return;
        //this.currentAnimationGroup = animationGroup
        if(animationGroup){
            this.appManager.hotspotManager.setHotspotsEnabled(true)
            this.currentAnimationGroup = animationGroup
            document.getElementById('bottomBar').classList.remove("hide")
            this.animationSlider.setValue(0)//(this.currentAnimationGroup.animatables[0].masterFrame / this.currentAnimationGroup.animatables[0].toFrame)*100)
            //this.currentAnimationGroup.goToFrame((this.currentAnimationGroup.animatables[0].toFrame/100)*this.animationSlider.getValue())
            this.currentAnimationGroup.play()
            this.currentAnimationGroup.pause()
            document.getElementById('animationTitle').innerHTML = this.currentAnimationGroup.name
        }else{
            if(this.currentAnimationGroup)
                this.pauseAnimation()
            document.getElementById('bottomBar').classList.add("hide")
        }
    }
    updateAnimationToSlider(){
        this.pauseAnimation()
        this.currentAnimationGroup.goToFrame((this.currentAnimationGroup.animatables[0].toFrame/100)*this.animationSlider.getValue())
        this.currentAnimationGroup.play()
        this.currentAnimationGroup.pause()
    }
    updateSliderToAnimation(){
        if (window.interval) {
            clearInterval(window.interval);
        }
        
        window.interval = setInterval(() => {
            //console.log(this.currentAnimationGroup.animatables[0])
            if(this.currentAnimationGroup.animatables[0].masterFrame == undefined || this.currentAnimationGroup.animatables[0].masterFrame >= (this.currentAnimationGroup.animatables[0].toFrame*0.97)){
                this.animationSlider.setValue(100)
                this.currentAnimationGroup.goToFrame((this.animationSlider.getValue()/100)*this.currentAnimationGroup.animatables[0].toFrame)
                this.pauseAnimation()
            }
            this.animationSlider.setValue((this.currentAnimationGroup.animatables[0].masterFrame/this.currentAnimationGroup.animatables[0].toFrame) *100)

        }, 100);
    }
}