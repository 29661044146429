export class Content{  

    constructor(appManager, content){
        this.appManager = appManager
        this.contentId = content.contentId
        this.contentTypeId = content.contentTypeId
        this.fileTypeId = content.fileTypeId
        this.name = content.name || "No name provided"
        this.units = content.units || "Units"
        this.meshParent = content.meshName
        this.parentContentId = content.parentContentId
        this.severityId = content.severityId
        this.markerSizeId = content.markerSizeId
        this.isPositioned = content.isPositioned
        this.hotspot = null
        this.hotspotMesh = null
        this.model = null
        if(content.transform){
            this.transform = content.transform
            this.root = new BABYLON.Mesh.CreateSphere("root_"+content.name, 16, 0.0001, this.appManager.scene);

            this.root.position = new BABYLON.Vector3(
                content.transform.positionX,
                content.transform.positionY,
                content.transform.positionZ
                )
        }
        if(content.transform.scaleX){
            this.scaling = new BABYLON.Vector3(
                content.transform.scaleX,
                content.transform.scaleY,
                content.transform.scaleZ
            )
        }
        if(this.contentTypeId == 1)
            this.url = content.url
        else if(this.contentTypeId == 3 ){
            this.url = content.url
            this.key = content.key
            this.pollingRate = content.pollingRate
        }
        else
            this.message = content.message


        this.initialise()
    }

    initialise(){
        this.addContentToDrawer()
        this.appManager.filterList.addFilterItem(this)
    }

    addContentToDrawer(){
        this.appManager.contentDrawer.addContentOption(this)
    }

    parentContent(){
        if(this.meshParent != null){
            let origin = this.root.position
            if(this.appManager.scene.getMeshByName(this.meshParent) != null){
                this.root.parent = this.appManager.scene.getMeshByName(this.meshParent);
                this.root.setAbsolutePosition(origin);
                this.root.scaling = new BABYLON.Vector3(-1,1,1)

                return;
            }
        }

        let contentParent = this.appManager.contentManager.findContentById(this.parentContentId)
        if(contentParent != null)
            this.root.parent = contentParent.root

        /* let contentEntity
        if(this.hotspot != null)
            contentEntity = this.hotspot
        else if(this.model != null)
            contentEntity = this.model
        else
            return;

        let contentParent = await this.appManager.contentManager.findContentById(this.parentContentId)
        console.log("RECEIVED",contentParent)

        let contentParentEntity
        console.log(this.parentContentId, contentParent)
        if(contentParent.hotspot != null)
            contentParentEntity = contentParent.hotspot
        else if(contentParent.model != null)
            contentParentEntity = contentParent.model
        else
            return;
        
        contentEntity.parent = contentParentEntity */
    }

    loadContent(){

    }

    dispose(){
        //if model
        //dispose model


        this.url = null
        this.contentId = null
        this.contentTypeId = null
        this.name = null
        this.parentContentId = null
        this.transform = null
    }
}